import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import '../styles/global.css'; 
import BaseLayout from "../components/BaseLayout"

// import '../css/blog-post.css';

export default function Template({ data }) {
    
  const { markdownRemark: post } = data
  return (
    <BaseLayout data={data}>
      <Helmet title={`web3 blog - ${post.frontmatter.title}`} />
      <div  className="flex flex-col justify-center ">
          
      <h1 class="font-bold font-sans break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl">
                        
                          {post.frontmatter.title}
                        
                      </h1>
        <div
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        
      </div>
    
    </BaseLayout>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`